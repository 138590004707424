import {initMap} from "./setup/google-map";

window._ = require('lodash');// jQuery
window.$ = window.jQuery = require('jquery');
require('fslightbox');
import CompressorManager from "./compressorjs/index.js";
window.compressorManager = new CompressorManager();

try {

    require('bootstrap');
    require('admin-lte');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// CKEditor 5
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
require('@ckeditor/ckeditor5-build-classic/build/translations/fr.js');
window.ClassicEditor = ClassicEditor;
require('./setup/ckeditor5.js');

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

require('select2/dist/js/select2.full');
require('select2/dist/js/i18n/fr.js');

require('./setup/select2.js');
(function($) {

    // Select 2
    $('select.select2')
        .removeClass('select2')
        .each(function() {
            let $element = $(this);
            let $emptyOption = $element.find('option[value=""]');
            $element.select2({
                placeholder: $emptyOption.text(),
                allowClear: $emptyOption.length > 0,
                containerCssClass: ':all:',
                tags: $element.hasClass('tag'),
            });
        });

    /*
     * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
     * see: https://github.com/select2/select2/issues/5993
     * see: https://github.com/jquery/jquery/issues/4382
     *
     * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
     */
    jQuery(document).on('select2:open', function (e) {
        window.setTimeout(function () {
            jQuery(".select2-container--open .select2-search__field").get(0).focus();
        }, 200);
    });

})(jQuery);
